import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';

import Auth0Context from '../Auth0Context';

import UserContext, { UserContextProvider } from './UserContext';
import EmailSettingsContext, {
  EmailSettingsContextProvider,
} from './EmailSettingsContext';

const BackendApiContext = createContext();

export default BackendApiContext;

export const BackendApiContextProvider = ({ children }) => {
  const { isAuth0Ready, isAuthenticated, getAccessToken } = useContext(
    Auth0Context
  );

  const [client, setClient] = useState({});

  const isBackendApiInitializable = useMemo(() => {
    return isAuth0Ready && isAuthenticated;
  }, [isAuth0Ready, isAuthenticated]);

  const isBackendApiReady = useMemo(() => {
    return Object.entries(client).length > 0;
  }, [client]);

  const initBackendApi = useMemo(() => {
    let isCall = false;
    return async () => {
      if (!isAuth0Ready || !isAuthenticated) {
        return;
      }

      if (isCall) {
        return;
      }

      isCall = true;

      const request = async operation => {
        const token = await getAccessToken();

        operation.setContext({
          headers: {
            authorization: token,
          },
        });
      };

      const requestLink = new ApolloLink((operation, forward) => {
        return new Observable(observer => {
          let handle;
          Promise.resolve(operation)
            .then(oper => request(oper))
            .then(() => {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            })
            .catch(observer.error.bind(observer));

          return () => {
            if (handle) {
              handle.unsubscribe();
            }
          };
        });
      });

      const client = new ApolloClient({
        link: ApolloLink.from([
          onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
              graphQLErrors.forEach(({ message, locations, path }) => {
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                );
              });
            }

            if (networkError) {
              console.log(`[Network error]: ${networkError}`);
            }
          }),
          requestLink,
          new HttpLink({
            uri: process.env.GATSBY_BACKEND_API_ENDPOINT,
          }),
        ]),
        cache: new InMemoryCache(),
      });

      setClient(client);
    };
  }, [isAuth0Ready, isAuthenticated, getAccessToken]);

  useEffect(() => {
    if (!isAuth0Ready || !isAuthenticated) {
      setClient({});
    }
  }, [isAuth0Ready, isAuthenticated]);

  return (
    <BackendApiContext.Provider
      value={{
        isBackendApiInitializable,
        isBackendApiReady,
        client,
        initBackendApi,
      }}
    >
      <ApolloProvider client={client}>
        <UserContextProvider>
          <EmailSettingsContextProvider>
            {children}
          </EmailSettingsContextProvider>
        </UserContextProvider>
      </ApolloProvider>
    </BackendApiContext.Provider>
  );
};

export const useBackendApi = () => {
  const backendApiContext = useContext(BackendApiContext);
  const userContext = useContext(UserContext);
  const emailSettingsContext = useContext(EmailSettingsContext);

  return {
    ...backendApiContext,
    ...userContext,
    ...emailSettingsContext,
  };
};
