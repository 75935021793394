import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useBackendApi } from '../index';

export const AVAILABLE_CLAIMS = [
  'given_name',
  'family_name',
  'given_name_kana',
  'family_name_kana',
  'birthdate',
  'gender',
];

const UserContext = createContext();

const QUERY_USER = gql`
  {
    user {
      user_id
      email
      email_verified
      user_metadata {
        given_name
        family_name
        given_name_kana
        family_name_kana
        birthdate
        gender
        tos_versions {
          client_id
          version
        }
      }
    }
  }
`;

const PATCH_USER_CLAIMS = gql`
  mutation PatchUserClaims(
    $given_name: String
    $family_name: String
    $given_name_kana: String
    $family_name_kana: String
    $birthdate: String
    $gender: Gender
  ) {
    patchUserClaims(
      input: {
        given_name: $given_name
        family_name: $family_name
        given_name_kana: $given_name_kana
        family_name_kana: $family_name_kana
        birthdate: $birthdate
        gender: $gender
      }
    ) {
      user_metadata {
        given_name
        family_name
        given_name_kana
        family_name_kana
        birthdate
        gender
      }
    }
  }
`;

const RENEW_TERMS_OF_SERVICE = gql`
  mutation RenewTermsOfService {
    renewTermsOfService {
      user_metadata {
        tos_versions {
          client_id
          version
        }
      }
    }
  }
`;

const CREATE_EMAIL_VERIFICATION_JOB = gql`
  mutation CreateEmailVerificationJob($client_id: String!) {
    createEmailVerificationJob(input: { client_id: $client_id }) {
      success
    }
  }
`;

export default UserContext;

export const UserContextProvider = ({ children }) => {
  const { isBackendApiReady } = useBackendApi();

  const [user, setUser] = useState(undefined);

  const isUserReady = useMemo(() => {
    if (!isBackendApiReady || typeof user === 'undefined') {
      return false;
    }

    return true;
  }, [isBackendApiReady, user]);

  const isRequireTosConfirmation = useMemo(() => {
    if (!isBackendApiReady || !user) {
      return false;
    }

    if (!user.user_metadata || !user.user_metadata.tos_versions) {
      return true;
    }

    return !user.user_metadata.tos_versions.some(tos => {
      return (
        tos.client_id === process.env.GATSBY_BACKEND_APP_CLIENT_ID &&
        tos.version === process.env.GATSBY_TERMS_OF_SERVICE_VERSION
      );
    });
  }, [isBackendApiReady, user]);

  const isRequireEmailVerification = useMemo(() => {
    if (!isBackendApiReady || !user) {
      return false;
    }

    if (user.user_id.indexOf('auth0|') === -1) {
      return false;
    }

    return !user.email_verified;
  }, [isBackendApiReady, user]);

  const isRequireUpdateClaims = useMemo(() => {
    if (!isBackendApiReady || !user) {
      return false;
    }

    if (!user.user_metadata) {
      return true;
    }

    return AVAILABLE_CLAIMS.some(claim => {
      const value = user.user_metadata[claim];
      return typeof value === 'undefined' || value === null;
    });
  }, [isBackendApiReady, user]);

  const currentTermsOfServiceVersion = useMemo(() => {
    if (!user || !user.user_metadata || !user.user_metadata.tos_versions) {
      return undefined;
    }

    return user.user_metadata.tos_versions.find(tos => {
      return tos.client_id === process.env.GATSBY_BACKEND_APP_CLIENT_ID;
    });
  }, [user]);

  const validateClaims = useCallback(params => {
    const existsAllParams = AVAILABLE_CLAIMS.every(app => {
      return params[app] && params[app].length > 0;
    });

    if (!existsAllParams) {
      return false;
    }

    if (isNaN(new Date(params.birthdate).getTime())) {
      return false;
    }

    return true;
  }, []);

  const [
    queryUser,
    { data: queryUserData, loading: isQueryUserLoading, error: queryUserError },
  ] = useLazyQuery(QUERY_USER);

  const [
    patchUserClaims,
    {
      data: patchUserClaimsData,
      loading: isPatchUserClaimsLoading,
      error: patchUserClaimsError,
    },
  ] = useMutation(PATCH_USER_CLAIMS);

  const [
    renewTermsOfService,
    {
      data: renewTermsOfServiceData,
      loading: isRenewTermsOfServiceLoading,
      error: renewTermsOfServiceError,
    },
  ] = useMutation(RENEW_TERMS_OF_SERVICE);

  const [
    createEmailVerificationJob,
    {
      loading: isCreateEmailVerificationJobLoading,
      error: createEmailVerificationJobError,
    },
  ] = useMutation(CREATE_EMAIL_VERIFICATION_JOB);

  useEffect(() => {
    if (queryUserData) {
      setUser(user => ({
        ...user,
        ...queryUserData.user,
      }));
    }
  }, [queryUserData]);

  useEffect(() => {
    if (patchUserClaimsData) {
      setUser(user => ({
        ...user,
        user_metadata: {
          ...user.user_metadata,
          ...patchUserClaimsData.patchUserClaims.user_metadata,
        },
      }));
    }
  }, [patchUserClaimsData]);

  useEffect(() => {
    if (renewTermsOfServiceData) {
      setUser(user => ({
        ...user,
        user_metadata: {
          ...user.user_metadata,
          tos_versions:
            renewTermsOfServiceData.renewTermsOfService.user_metadata
              .tos_versions,
        },
      }));
    }
  }, [renewTermsOfServiceData]);

  useEffect(() => {
    if (!isBackendApiReady) {
      setUser(undefined);
      return;
    }

    queryUser();
  }, [isBackendApiReady, queryUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        isUserReady,
        isRequireTosConfirmation,
        isRequireEmailVerification,
        isRequireUpdateClaims,
        currentTermsOfServiceVersion,
        validateClaims,
        queryUser,
        isQueryUserLoading,
        queryUserError,
        patchUserClaims,
        isPatchUserClaimsLoading,
        patchUserClaimsError,
        renewTermsOfService,
        isRenewTermsOfServiceLoading,
        renewTermsOfServiceError,
        createEmailVerificationJob,
        isCreateEmailVerificationJobLoading,
        createEmailVerificationJobError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
