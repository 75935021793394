const React = require('react');

const { Auth0ContextProvider } = require('./src/contexts/Auth0Context');
const {
  BackendApiContextProvider,
} = require('./src/contexts/BackendApiContext');

exports.wrapRootElement = ({ element }) => (
  <Auth0ContextProvider>
    <BackendApiContextProvider>{element}</BackendApiContextProvider>
  </Auth0ContextProvider>
);

// Fetch polyfill for IE11
require('whatwg-fetch');

// Remove polyfill for IE11
(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

exports.onClientEntry = () => {};

exports.onRouteUpdate = ({ prevLocation }) => {
  if (prevLocation && window.dataLayer) {
    window.dataLayer.push({ event: 'gtm.load' });
  }
};

exports.shouldUpdateScroll = ({ prevRouterProps }) => {
  if (prevRouterProps) {
    return true;
  }

  const navigationTimings = window.performance.getEntriesByType('navigation');

  if (navigationTimings.length > 0) {
    if (navigationTimings[0].type === 'reload') {
      return true;
    }
  } else {
    if (window.performance.navigation.type === 1) {
      return true;
    }
  }

  window.scrollTo(0, 0);
  return false;
};
