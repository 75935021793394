import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { navigate } from 'gatsby';
import { Auth0Client } from '@auth0/auth0-spa-js';

const Auth0Context = createContext();

export default Auth0Context;

export const Auth0ContextProvider = ({ children }) => {
  const [client, setClient] = useState(undefined);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoginPopupOpened, setIsLoginPopupOpened] = useState(false);

  const isBrowser = useMemo(() => typeof window !== 'undefined', []);

  const isAuth0Ready = useMemo(() => {
    if (!isBrowser) {
      return true;
    }

    return !!client;
  }, [isBrowser, client]);

  const getAccessToken = useMemo(() => {
    return async options => {
      if (!client) {
        throw new Error('Auth0Context is not ready.');
      }

      if (!isAuthenticated) {
        new Error('Login required.');
      }

      try {
        return await client.getTokenSilently(options);
      } catch (error) {
        switch (error.error) {
          case 'consent_required':
            return await client.getTokenWithPopup(options);
          case 'login_required':
            await client.loginWithRedirect({
              ...options,
              cacheLocation: 'localstorage',
              useRefreshTokens: true,
              appState: {
                targetUrl: window.location.href.replace(
                  window.location.origin,
                  ''
                ),
              },
            });
            return;
          default:
            console.log(error);
            client.logout({
              client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
              returnTo: process.env.GATSBY_AUTH0_LOGOUT_URI,
            });
            return;
        }
      }
    };
  }, [client, isAuthenticated]);

  const loginWithPopup = useCallback(() => {
    if (!client) {
      return;
    }

    (async () => {
      setIsLoginPopupOpened(true);

      try {
        await client.loginWithPopup();
        const isAuthenticated = await client.isAuthenticated();
        setIsAuthenticated(isAuthenticated);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoginPopupOpened(false);
      }
    })();
  }, [client]);

  const loginWithRedirect = useCallback(
    appState => {
      if (!client) {
        return;
      }

      (async () => {
        await client.loginWithRedirect({
          appState,
        });
      })();
    },
    [client]
  );

  const signupWithRedirect = useCallback(
    appState => {
      if (!client) {
        return;
      }

      (async () => {
        await client.loginWithRedirect({
          appState: {
            ...appState,
            targetUrl: '/callback',
          },
          screen_hint: 'signup',
        });
      })();
    },
    [client]
  );

  const logout = useCallback(() => {
    if (!client) {
      return;
    }

    client.logout({
      client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
      returnTo: process.env.GATSBY_AUTH0_LOGOUT_URI,
    });
  }, [client]);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    (async () => {
      try {
        const client = new Auth0Client({
          domain: process.env.GATSBY_AUTH0_DOMAIN,
          client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
          redirect_uri: process.env.GATSBY_AUTH0_REDIRECT_URI,
          audience: process.env.GATSBY_BACKEND_API_AUDIENCE,
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
        });

        if (
          window.location.pathname.indexOf('/email-verification') === -1 &&
          window.location.search.includes('code=')
        ) {
          try {
            const { appState } = await client.handleRedirectCallback();

            if (appState) {
              if (appState.targetUrl) {
                navigate(appState.targetUrl);
              } else {
                navigate('/');
              }
            }
          } catch (error) {
            console.log(error);
            navigate('/');
          }
        } else {
          if (
            window.location.pathname.indexOf('/email-verification') === 0 &&
            window.location.search.length !== 0
          ) {
            const params = new URLSearchParams(
              document.location.search.substring(1)
            );

            if (params.get('success')) {
              navigate('/email-verification-success');
            }
          }

          await client.checkSession();
        }

        const isAuthenticated = await client.isAuthenticated();
        setIsAuthenticated(isAuthenticated);

        setClient(client);
      } catch (error) {
        console.log(error);
        window.localStorage.clear();
        window.location.reload();
      }
    })();
  }, [isBrowser]);

  return (
    <Auth0Context.Provider
      value={{
        isBrowser,
        isAuth0Ready,
        isAuthenticated,
        isLoginPopupOpened,
        loginWithPopup,
        loginWithRedirect,
        signupWithRedirect,
        logout,
        getAccessToken,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0 = () => {
  const auth0Context = useContext(Auth0Context);

  return auth0Context;
};
