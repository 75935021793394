import React, { createContext, useState, useMemo, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useBackendApi } from '../index';

const EmailSettingsContext = createContext();

const QUERY_EMAIL_SETTINGS = gql`
  {
    emailSettings {
      email
      delivery
    }
  }
`;

const CREATE_EMAIL_SETTINGS = gql`
  mutation CreateEmailSettings(
    $email: String!
    $delivery: EmailSettingsDelivery!
  ) {
    createEmailSettings(input: { email: $email, delivery: $delivery }) {
      email
      delivery
    }
  }
`;

const UPDATE_EMAIL_SETTINGS = gql`
  mutation UpdateEmailSettings(
    $email: String!
    $delivery: EmailSettingsDelivery!
  ) {
    updateEmailSettings(input: { email: $email, delivery: $delivery }) {
      email
      delivery
    }
  }
`;

export const EMAIL_SETTINGS_DELIVERY = { ALLOW: 'Allow', DENY: 'Deny' };

export default EmailSettingsContext;

export const EmailSettingsContextProvider = ({ children }) => {
  const { isBackendApiReady, user } = useBackendApi();

  const [emailSettings, setEmailSettings] = useState(undefined);

  const isEmailSettingsReady = useMemo(() => {
    if (!isBackendApiReady || typeof emailSettings === 'undefined') {
      return false;
    }

    return true;
  }, [isBackendApiReady, emailSettings]);

  const isRequireCreateEmailSettings = useMemo(() => {
    if (!isBackendApiReady || !user) {
      return false;
    }

    return user.email_verified && emailSettings === null;
  }, [isBackendApiReady, user, emailSettings]);

  const isRequireVerifiedSocilaLoginEmail = useMemo(() => {
    if (!isBackendApiReady || !user) {
      return false;
    }

    if (user.user_id.indexOf('auth0|') !== -1) {
      return false;
    }

    return !user.email || !user.email_verified;
  }, [isBackendApiReady, user]);

  const [
    queryEmailSettings,
    {
      data: queryEmailSettingsData,
      loading: isQueryEmailSettingsLoading,
      error: queryEmailSettingsError,
    },
  ] = useLazyQuery(QUERY_EMAIL_SETTINGS);

  const [
    createEmailSettings,
    {
      data: createEmailSettingsData,
      loading: isCreateEmailSettingsLoading,
      error: createEmailSettingsError,
    },
  ] = useMutation(CREATE_EMAIL_SETTINGS);

  const [
    updateEmailSettings,
    {
      data: updateEmailSettingsData,
      loading: isUpdateEmailSettingsLoading,
      error: updateEmailSettingsError,
    },
  ] = useMutation(UPDATE_EMAIL_SETTINGS);

  useEffect(() => {
    if (queryEmailSettingsData) {
      setEmailSettings(emailSettings => {
        if (queryEmailSettingsData.emailSettings === null) {
          return null;
        } else {
          return {
            ...emailSettings,
            ...queryEmailSettingsData.emailSettings,
          };
        }
      });
    }
  }, [queryEmailSettingsData]);

  useEffect(() => {
    if (createEmailSettingsData) {
      setEmailSettings(emailSettings => ({
        ...emailSettings,
        ...createEmailSettingsData.createEmailSettings,
      }));
    }
  }, [createEmailSettingsData]);

  useEffect(() => {
    if (updateEmailSettingsData) {
      setEmailSettings(emailSettings => ({
        ...emailSettings,
        ...updateEmailSettingsData.updateEmailSettings,
      }));
    }
  }, [updateEmailSettingsData]);

  useEffect(() => {
    if (!isBackendApiReady) {
      setEmailSettings(undefined);
      return;
    }

    queryEmailSettings();
  }, [isBackendApiReady, queryEmailSettings]);

  return (
    <EmailSettingsContext.Provider
      value={{
        emailSettings,
        isEmailSettingsReady,
        isRequireCreateEmailSettings,
        isRequireVerifiedSocilaLoginEmail,
        queryEmailSettings,
        isQueryEmailSettingsLoading,
        queryEmailSettingsError,
        createEmailSettings,
        isCreateEmailSettingsLoading,
        createEmailSettingsError,
        updateEmailSettings,
        isUpdateEmailSettingsLoading,
        updateEmailSettingsError,
      }}
    >
      {children}
    </EmailSettingsContext.Provider>
  );
};
